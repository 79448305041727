.card {
    height: 100%;
}

.card img {
    width: 416px;
    height: 250px;
}


/*# sourceMappingURL=OurServices.css.map */