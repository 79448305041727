@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
.banner img {
    width: 100%;
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    filter: invert(15%);
    filter: brightness(0.3);
}

.banner .banner-text {
    width: 100%;
    color: white;
    position: absolute;
    top: 20%;
    left: 0;
}

.banner .banner-text h1 {
    font-size: 50px;
    font-family: Raleway, sans-serif;
    font-weight: bold;
    padding-bottom: 15px;
}

.banner .banner-text p {
    font-size: 35px;
    font-family: sans-serif;
    line-height: 50px;
    font-weight: 100;
}