@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
footer {
    width: 100%;
    height: 400px;
    color: white;
    background-color: black;
    padding-top: 25px;
}

footer .footer-title h4 {
    color: #01d28e;
    font-family: Pacifico;
    font-size: 25px;
    padding-bottom: 20px;
}

footer .footer-title h4:hover {
    color: white;
    cursor: pointer;
}

footer .footer-text {
    color: white;
    font-size: 19px;
    line-height: 30px;
    font-family: Poppins;
    font-weight: 400;
}

footer .footer-menu-title {
    font-family: Poppins;
    padding-bottom: 20px;
}

footer .footer-menu-text {
    color: white;
    font-size: 19px;
    line-height: 30px;
    font-family: Poppins;
    font-weight: 400;
}

footer .footer-menu-text ul {
    padding: 0;
}

footer .footer-menu-text ul li {
    list-style-type: none;
}

footer .footer-menu-text ul li a {
    color: white;
    text-decoration: none;
    line-height: 35px;
}

footer .footer-menu-text ul li a:hover {
    color: #0d6efd;
}

footer .footer-info-title {
    font-family: Poppins;
    padding-bottom: 20px;
    font-weight: 600;
}

footer .footer-icons {
    font-size: 25px;
}

@media(max-width:768px) {
    footer {
        height: auto;
    }
}


/*# sourceMappingURL=Footer.css.map */