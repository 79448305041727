@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.main-text {
    padding: 40px 80px 80px 80px;
    background-color: #f5f4fb;
}

.main-text h2 {
    color: #333333;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    padding-bottom: 10px;
}

.main-text p {
    color: #666666;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}

@media (max-width:768px) {
    .main-text {
        padding: 40px 0px 0px 00px;
        background-color: #f5f4fb;
    }
}


/*# sourceMappingURL=MainText.css.map */